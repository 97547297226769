#quejas .chat-box {
   height: 70vh;
   overflow-y: scroll;
}

#quejas .mensaje-box {
   border-radius: 10px;
   width: 85%;
}

.form-control.send-msg {
   display: block;
   width: 100%;
   height: calc(1.5em + 0.75rem + 2px);
   padding: 1.3rem 0.75rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
   background-color: #fff;
   background-clip: padding-box;
   border: none;
   border-radius: 0;
   /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
}

.form-control.send-msg:focus {
   color: #333333;
   /* border-color: #eb6c40; */
   box-shadow: none;
}
