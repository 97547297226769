.navbar-brand:hover {
   background: #212121;
   border-radius: 4px;
}

.toggler-sidebar {
   padding: 10px;
   border: 1px solid #212121;
   border-radius: 4px;
}

.toggler-sidebar:hover {
   color: white;
   border: 1px solid black;
}
