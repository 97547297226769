.rbt-input-hint-container {
   margin-top: 1rem !important;
}

.rbt-menu.dropdown-menu.show {
   padding: 0.25rem 0;
}

.rbt-menu.dropdown-menu.show a.dropdown-item {
   padding: 0.45rem 1.5rem;
}

.rbt-menu.dropdown-menu.show a.dropdown-item:hover {
   background-color: #deecff !important;
}

.leyenda .media img {
   box-shadow: none;
   width: 35px;
}

.foto-chofer {
   width: 70px;
}

#monitor div.rbt-input-hint-container input {
   text-indent: 1.6rem;
}

#monitor .stats-card {
   min-height: 218.4px;
}

@media (min-width: 1200px) { 
   #monitor .stats-card {
      min-height: 276px;
   }
}