.icon-desconectado {
   width: 48px;
}

@media (max-width: 1199.98px) { 
   .icon-desconectado {
      width: 35px;
      
   }
   .media-body {
      word-wrap: anywhere;
   }
}