.sidebar {
   min-width: 250px;
   max-width: 250px;
   height: 100vh;
   background-color: #4f4f4f;
   color: #efefef;
   /* Transitions */
   -moz-transition: all 0.3s ease;
   -webkit-transition: all 0.3s ease;
   -o-transition: all 0.3s ease;
   transition: all 0.3s ease;
}

.sidebar-links {
   padding: 0rem 1rem;
}

.sidebar-links li {
   border: none;
   background-color: transparent;
}

.sidebar-links a.active,
.sidebar-links a:hover {
   background: #fd8500;
   box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.35);
   border-radius: 4px;
}

.sidebar-links a {
   /* display: block; */
   /* width: 100%; */
   /* padding: 10px 20px; */
   color: #efefef;
   /* text-decoration: none; */
   /* font-size: 1.1em; */
   /* Transitions */
   -moz-transition: all 0.2s ease;
   -webkit-transition: all 0.2s ease;
   -o-transition: all 0.2s ease;
   transition: all 0.2s ease;
   outline: none;
}

.sidebar-links a li.list-group-item {
   padding: 0.75rem 0rem 0.75rem 1rem;
}

/** ******************************************** */
/** Collapsed */
/** ******************************************** */

.sidebar.collapsed {
   max-width: 80px;
   min-width: 80px;
}

.sidebar.collapsed header img {
   padding: 0.7rem 0rem;
}

.sidebar.collapsed .sidebar-links {
   padding: 0rem;
}

.sidebar.collapsed .sidebar-links a li.list-group-item {
   padding: 0rem;
}

.sidebar.collapsed .sidebar-links a {
   padding: 10px;
   font-size: 0.8em;
   text-align: center;
   /* overflow: hidden;
   word-wrap: break-word; */
}

.sidebar.collapsed .sidebar-links .fas,
.sidebar.collapsed .sidebar-links .fa {
   width: 100%;
   font-size: 2.5em;
   margin-left: 0;
   margin-right: 0;
}

.sidebar.collapsed .sidebar-links a span {
   display: none;
}

/** ******************************************** */
/** Movil */
/** ******************************************** */

@media (max-width: 768px) {
   .sidebar.collapsed {
      max-width: 20px;
      min-width: 20px;
   }

   .sidebar.collapsed .sidebar-links {
      display: none;
   }
}

@media (max-width: 1199.98px) {
   .sidebar {
      font-size: 0.7rem;
      min-width: 200px;
      /* max-width: 200px; */
   }
}

@media (max-height: 768px) {
   .sidebar {
      overflow-y: scroll;
   }
}