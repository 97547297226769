/****************************************/
/**               Panel                 */
/****************************************/

.panel-admin .panel-body {
  display: flex;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.panel-admin .panel-body .right {
  overflow-y: auto;
  flex-basis: 100%;
  padding: 0px 0px 50px 0;
}

.pages-container {
  padding: 2rem;
}

/****************************************/
/**                INPUT                */
/****************************************/

.md-form.md-outline input[type="color"] {
  transition: all 0.3s;
  outline: none;
  box-shadow: none;
  border: 1px solid #dadce0;
  border-radius: 4px;
  background-color: transparent;
  box-sizing: border-box;
  height: calc(1.5em + 0.75rem + 2px);
}

/****************************************/
/**                MAPA                 */
/****************************************/

.leaflet-container {
  height: 60vh;
  width: 100%;
}

.leaflet-container .leaflet-pane {
  z-index: 0;
}

.leaflet-top {
  z-index: 0;
}

.leaflet-control {
  z-index: 0;
}

/****************************************/
/**                TABS                 */
/****************************************/
.swal2-html-container {
  z-index: 1061 !important;
}

.MuiAppBar-root {
  z-index: 1000 !important;
}

.modal-footer {
  justify-content: center;
}

#monitor .MuiPaper-root .MuiPaper-elevation4 .MuiAppBar-root,
#monitor .MuiPaper-root .MuiPaper-elevation4 .MuiAppBar-root .MuiAppBar-positionStatic .bg-primary .MuiAppBar-colorPrimary {
  z-index: 1;
}

#monitor .MuiTabs-indicator,
#clientes .MuiTabs-indicator,
#viajes .MuiTabs-indicator {
  background-color: white !important;
}

#choferes .MuiTabs-flexContainer,
#zonas .MuiTabs-flexContainer,
#grupos .MuiTabs-flexContainer {
  border-bottom: 1px solid #e0e0e0;
}

#zonas #usuarios .MuiTabs-flexContainer {
  border-bottom: none;
}

#zonas #usuarios .MuiTabs-indicator {
  height: 3px;
}

#choferes .MuiTabs-indicator,
#zonas .MuiTabs-indicator,
#saldos .MuiTabs-indicator,
#grupos .MuiTabs-indicator {
  height: 5px;
}

#choferes .MuiTab-wrapper,
#zonas .MuiTab-wrapper,
#grupos .MuiTab-wrapper {
  font-size: 1rem;
}

#choferes .Tab-stat-indicator {
  height: 2px;
  background-color: white;
}

#choferes button:focus,
#grupos button:focus,
#saldos button:focus,
#zonas button:focus {
  outline: none;
}

/****************************************/
/**                CARDS                */
/****************************************/
.card-header-shadow {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  z-index: 1;
}

.card-icon {
  padding: 1.4375rem;
  /* font-size: 2rem; */
  color: #fff;
  -webkit-box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.card-icon-svg {
  padding: 1.5rem 1.375rem;
  /* font-size: 2rem; */
  color: #fff;
  -webkit-box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

/****************************************/
/**                TABLES               */
/****************************************/
.react-bootstrap-table .table tbody td {
  vertical-align: middle;
}

.react-bs-table-no-data {
  text-align: center;
}

.detalles-font-size {
  font-size: 0.9rem !important;
}

.pagination {
  justify-content: flex-end !important;
}

.pagination .active.page-item .page-link {
  z-index: 1;
  color: #fff;
  background-color: #eb6c40;
  border-color: #eb6c40;
}

.pagination .page-item.active .page-link:hover {
  background-color: #ce5c36;
}

.pagination .page-item .page-link {
  z-index: 1;
  color: black;
}

@media (max-width: 1199.98px) {
  .pages-container {
    font-size: 0.7rem;
  }
}

