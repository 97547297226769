.label {
   font-size: 1.13rem;
   padding: 0.3rem 1rem;
   box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.1);
   border-radius: 5px;
}

@media (max-width: 1199.98px) { 
   .label {
      font-size: 0.7rem;
   }
}