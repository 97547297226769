/****************************************/
/**               Fonts                 */
/****************************************/
@font-face {
   font-family: 'Rubik';
   src: url('./assets/fonts/Rubik/Rubik-Regular.ttf');
   font-weight: normal;
}

@font-face {
   font-family: 'Rubik';
   src: url('./assets/fonts/Rubik/Rubik-Medium.ttf');
   font-weight: 500;
}

@font-face {
   font-family: 'Rubik';
   src: url('./assets/fonts/Rubik/Rubik-Bold.ttf');
   font-weight: bold;
}

/****************************************/
/**             Generales               */
/****************************************/
body {
   margin: 0;
   font-family: Rubik, sans-serif;
   font-weight: normal;
   color: #4f4f4f;
   background: url('./assets/images/kargo-bg-3.png');
   background-position: center;
   background-repeat: repeat;
   background-size: 85%;
}

.cursor-pointer {
   cursor: pointer;
}

.font-weight-medium {
   font-weight: 500 !important;
}

.font-size-18 {
   font-size: 1.125rem;
}

/****************************************/
/**         Custom MDBoostrap           */
/****************************************/

/* BUTTONS */
.btn.btn-sm {
   padding: 0.5rem 0.5rem;
   border-radius: 5px;
}

.btn-default {
   text-transform: none !important;
   background-color: #fd8500 !important;
   border-radius: 5px;
   box-shadow: none !important;
   font-weight: 600;
   font-size: 14px;
   line-height: normal;
   color: #ffffff;
   /* padding: 8px 100px; */
}

.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled).active {
   background-color: #ce5c36 !important;
}

.btn-default:hover,
.btn-default:active {
   background-color: #ce5c36 !important;
   color: #ffffff;
}

.btn-outline-default,
.btn-outline-default:hover,
.btn-outline-default:focus,
.btn-outline-default:active,
.btn-outline-default:active:focus,
.btn-outline-default.active {
   border-color: #eb6c40 !important;
   background-color: transparent !important;
   color: #eb6c40 !important;
   border-radius: 5px;
}

.btn-outline-default:not([disabled]):not(.disabled):active,
.btn-outline-default:not([disabled]):not(.disabled).active {
   border-color: #eb6c40 !important;
   border-radius: 5px;
}

.btn-primary {
   color: #fff;
   background-color: #0f6deb !important;
   border-color: #0f6deb !important;
}

.btn-danger {
   color: #fff;
   background-color: #e83c00 !important;
   border-color: #e83c00 !important;
}

.btn-warning {
   color: #fff;
   background-color: #f2994a !important;
}

.btn-top-action {
   border-color: #f2994a !important;
   border-radius: 5px;
   box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
   font-size: 1rem;
   padding: 0.4375rem 1.125rem;
}

/* FORMS */
.md-form.md-outline input[type='text']:focus:not([readonly]),
.md-form.md-outline input[type='password']:focus:not([readonly]),
.md-form.md-outline input[type='email']:focus:not([readonly]),
.md-form.md-outline input[type='url']:focus:not([readonly]),
.md-form.md-outline input[type='time']:focus:not([readonly]),
.md-form.md-outline input[type='date']:focus:not([readonly]),
.md-form.md-outline input[type='datetime-local']:focus:not([readonly]),
.md-form.md-outline input[type='tel']:focus:not([readonly]),
.md-form.md-outline input[type='number']:focus:not([readonly]),
.md-form.md-outline input[type='search-md']:focus:not([readonly]),
.md-form.md-outline input[type='search']:focus:not([readonly]),
.md-form.md-outline textarea:focus:not([readonly]) {
   border-color: #eb6c40;
   box-shadow: inset 0px 0px 0px 1px #eb6c40;
}

.md-form.md-outline input[type='text']:focus.date-input {
   border-color: #eb6c40;
   box-shadow: inset 0px 0px 0px 1px #eb6c40;
}

.md-form.md-outline input[type='text']:focus:not([readonly])+label,
.md-form.md-outline input[type='password']:focus:not([readonly])+label,
.md-form.md-outline input[type='email']:focus:not([readonly])+label,
.md-form.md-outline input[type='url']:focus:not([readonly])+label,
.md-form.md-outline input[type='time']:focus:not([readonly])+label,
.md-form.md-outline input[type='date']:focus:not([readonly])+label,
.md-form.md-outline input[type='datetime-local']:focus:not([readonly])+label,
.md-form.md-outline input[type='tel']:focus:not([readonly])+label,
.md-form.md-outline input[type='number']:focus:not([readonly])+label,
.md-form.md-outline input[type='search-md']:focus:not([readonly])+label,
.md-form.md-outline input[type='search']:focus:not([readonly])+label,
.md-form.md-outline textarea:focus:not([readonly])+label {
   color: #4f4f4f;
}

.md-form.md-outline label {
   top: 0rem;
}

label {
   margin-bottom: 8px;
}

.md-form.md-outline {
   height: 40px;
}

.md-form.md-outline label {
   font-size: 1rem;
}

.md-form.md-outline {
   margin-top: 1rem;
   margin-bottom: 0rem;
}

.md-form .prefix~input,
.md-form .prefix~textarea {
   margin-left: 0 !important;
   width: 100% !important;
   text-indent: 1rem;
}

/* .md-form.md-outline .prefix + .rdt .form-control,
.md-form.md-outline .prefix + .rdt.rdtOpen .form-control {
   text-indent: 1rem;
} */

.md-form.md-outline .prefix+.rdt+label,
.md-form.md-outline .prefix+.rdt.rdtOpen+label {
   text-indent: 0rem;
}

.md-form.md-outline .prefix {
   left: 9px;
   top: 0.8rem;
   font-size: 16px;
}

.md-form.md-outline .prefix.active {
   color: #6c757d;
}

.md-form.md-outline .prefix~label {
   margin-left: 0;
   text-indent: 1.2rem;
}

.md-form.md-outline .prefix.active~label {
   margin-left: 0;
   text-indent: 0;
}

/* .md-form.md-outline .form-control {
   padding: 0.375rem 0.75rem;
} */

.caret {
   color: #fd8500 !important;
}

.md-form .form-control {
   margin: 0 !important;
}

.form-control:focus {
   color: #333333;
   border-color: #fd8500;
   box-shadow: inset 0px 0px 0px 1px #eb6c40;
}

.md-form .form-control:disabled,
.md-form .form-control[readonly] {
   background-color: #e9ecef !important;
   opacity: 1 !important;
}

.md-form .form-control.date-input[readonly] {
   background-color: white !important;
}

.invalid-field {
   width: 100%;
   margin-top: 0.25rem;
   font-size: 80%;
   color: #dc3545;
}

/* BACKGROUNDS */
.bg-primary {
   background-color: #fd8500 !important;
}

.bg-dark-orange {
   background-color: #fd8500 !important;
}

.bg-warning {
   background-color: #fdae15 !important;
}

.bg-success-light {
   background-color: #6fcf97 !important;
}

.bg-success-dark {
   background-color: #27ae60 !important;
}

.bg-info {
   background-color: #0f6deb !important;
}

.bg-light {
   background-color: #f2f2f2 !important;
}

/* BADGES */
.badge {
   display: inline-block;
   padding: 0.8em 1.2em;
   font-size: 100%;
   font-weight: normal;
   line-height: 1;
   text-align: center;
   white-space: nowrap;
   vertical-align: baseline;
   border-radius: 0.25rem;
   box-shadow: none;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
}

.badge-primary {
   background-color: #0f6deb !important;
   color: #fff !important;
}

.badge-warning {
   background-color: #fdae15 !important;
   color: #fff !important;
}

.badge-success {
   background-color: #219653 !important;
   color: #fff !important;
}

/* ALERTS */
.alert {
   font-size: 0.8rem;
   padding: 0.75rem 0.7rem 0.75rem 1.25rem;
}

.Toastify__toast--success {
   background-color: #ceecdb;
}

.Toastify__toast--error {
   background-color: #f8c5c5;
}

.Toastify__toast--info {
   background-color: #cce5ff;
}

.Toastify__toast--warning {
   background-color: #fff3cd;
}

/* PILLS */
.md-pills li {
   padding: 0.6rem;
}

.nav-justified .nav-item {
   flex-basis: 0;
   flex-grow: 0.1;
   text-align: center;
}

.pills-rounded .nav-item .nav-link {
   border-radius: 10em;
}

.pills-deep-blue .nav-link.active {
   background-color: #0f6deb !important;
}

.md-pills .nav-link.active {
   box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
   color: #fff;
   /* background-color: #2bbbad; */
}

.md-pills .nav-link.active:hover {
   box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
   color: #fff;
}

.md-pills .nav-link:hover,
.md-pills .nav-link:hover::after {
   background-color: rgba(158, 158, 158, 0.3);
}

.md-pills .nav-link {
   transition: all 0.4s;
   border-radius: 2px;
   color: #666;
   text-align: center;
}

.iconoVehiculo {
   max-width: 200px;
   max-height: 200px;
}

.non-resizable-textarea {
   resize: none;
   /* Desactiva el redimensionamiento del textarea */
}

/* .nav-link {
   display: block;
   padding: 0.5rem 1rem;
} */